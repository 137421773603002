import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";





  //Connect Button
export const StyledButton = styled.button`
  padding: 0px;
  border-radius: 0px;
  border: solid 2px;
  background-color: var(--secondary);
  font-weight: bold;
  color: #000000;
  height: 50px;
  width: 150px;
  cursor: pointer;
  box-shadow: 0px 0px 0px 0px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 0px 0px 0px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 0px 0px 0px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledForm = styled.form`
  padding: 1px;
  border-radius: 0px;
  border: solid 1px;
  height: 50px;
  width: 100px;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
`;

export const StyledInput = styled.input`
  padding: 5px;
  height: 100%;
  width: 100%;
  border-radius: 0px;
  border: solid 0px;
  background-color: #ffffff;
  opacity: 0.85;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
`;

export const SmartContractButton = styled.button`
  padding: 10px;
  border-radius: 0px;
  border: solid 2px;
  background-color: rgba(108,127,72,0.5);
  padding: 0px;
  font-weight: bold;
  color: #000000;
  height: 30px;
  width: 150px;
  cursor: pointer;
  text-align: start;
  box-shadow: 0px 0px 0px 0px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 0px 0px 0px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 0px 0px 0px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: left;
  justify-content: left;
  box-shadow: 0px 0px 0px 0px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 0px 0px 0px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 0px 0px 0px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
//Container Settings
export const ResponsiveWrapper = styled.div` 
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @media (min-width: 1250px) { 
    flex-direction: row;
  }
`;
// Logo
export const StyledLogo = styled.img`
  width: 400px;
  @media (min-width: 625px) {
    width: 600px;
  }
  @media (min-width: 1250px) {
    width: 800px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;


// rotating image
export const StyledImg = styled.img` 
  box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.85);
  border: 0px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 0%;
  width: 350px;
  @media (min-width: 625px) {
    width: 500px;
  }
  @media (min-width: 1250px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: #000000;
  text-decoration: underline;
  font-size: 32px;
`;

function App() {
  
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [burningNft, setBurningNft] = useState(false);
  const [mintFeedback, setMintFeedback] = useState(`Click "MINT" to mint your NFT.`);
  const [burnFeedback, setBurnFeedback] = useState(`Click "BURN" to burn your NFT.`);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    INCREMENT_WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  

  const mintNFTs = () => {
    let basecost = (CONFIG.WEI_COST);
    let mintedSupplyCounter = Number(data.getMintedSupplyCounter);
    let nextID = mintedSupplyCounter + 1;
    let addcost = (CONFIG.INCREMENT_WEI_COST);
    let cost = basecost + addcost * mintedSupplyCounter;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost);
    let totalGasLimit = String(gasLimit);
    setMintFeedback(`Minting your Scenic Cup ...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .safeMint(blockchain.account)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setMintFeedback("Sorry, something went wrong - please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setMintFeedback(
          `Minting successful! The Scenic Cup with the ID ${nextID} is now yours! Go visit Quixotic.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const burnNFTs = () => {
    let burnInputID = document.getElementById("burnInput").value;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setBurnFeedback(`Burning your Scenic Cup ...`);
    setBurningNft(true);
    blockchain.smartContract.methods
      .burn(burnInputID)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setBurnFeedback("Sorry, something went wrong - please try again later.");
        setBurningNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setBurnFeedback(
          `The Scenic Cup has been burned! Go fill in the redemption form to have the physical Scenic Cup delivered to you.`
        );
        setBurningNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
    
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  let mintcost = (CONFIG.DISPLAY_COST + (CONFIG.INCREMENT_DISPLAY_COST * data.getMintedSupplyCounter));
  let rounddisplay = Math.round(mintcost * 1000) / 1000.0;
  let mintdisplay = String(rounddisplay); 
  console.log(mintcost);
  return (
    <s.Screen>
      <s.Container // Container for Content
        flex={1}
        ai={"left"}
        style={{ padding: 48, backgroundColor: "var(--primary)" }}
        image={"/config/images/bg_top.png"}
      >
        <ResponsiveWrapper flex={1} style={{ padding: 0 }}>
          <s.Container // Header Container
        >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ paddingLeft: 64, paddingRight: 64, paddingTop: 0, paddingBottom: 12 }}>
          <s.Container // Stats Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(256,256,256,0.85)", 
              padding: 4, 
              borderRadius: 0, 
              border: "0px solid #000000",
              boxShadow: "0px 0px 1.5px 1.5px rgba(0,0,0,1)",
              width: "100%",
            }}
          >
            <ResponsiveWrapper flex={1} style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}>
            <s.Container // minted
            flex={1}
            jc={"center"}
            ai={"center"}
            >
              <s.TextTitle style={{ textAlign: "center", color: "#000000" }}> 
            {blockchain.account === "" || blockchain.smartContract === null ? (
              <>
              minted: ... / {CONFIG.MAX_SUPPLY}
              </>
              ):(
              <
              >
              minted: {Number(data.getMintedSupplyCounter)} / {CONFIG.MAX_SUPPLY} 
              </>)
            }
            </s.TextTitle>
            </s.Container>
            <s.Container // redeemed
            flex={1}
            jc={"center"}
            ai={"center"}
            >
              <s.TextTitle style={{ textAlign: "center", color: "#000000" }}> 
              redeemed: 5 / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            </s.Container>
            <s.Container // burned
            flex={1}
            jc={"center"}
            ai={"center"}
            >
              <s.TextTitle style={{ textAlign: "center", color: "#000000" }}> 
            {blockchain.account === "" || blockchain.smartContract === null ? (
              <>
              burned: ... / {CONFIG.MAX_SUPPLY}
              </>
              ):(
              <
              >
              burned: {data.getBurnedSupplyCounter} / {CONFIG.MAX_SUPPLY} 
              </>)
            }
            </s.TextTitle>
            </s.Container>
            </ResponsiveWrapper>
          </s.Container>
        </ResponsiveWrapper>
        <ResponsiveWrapper flex={1} style={{ paddingLeft: 64, paddingRight: 64, paddingTop: 16, paddingBottom: 16 }}> 
          <s.Container // Mint Container
            flex={2}
            jc={"flex-start"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(256,256,256,0.85)", // background of container
              padding: 8, // space around container content
              borderRadius: 0, // radius of container border
              border: "0px var(--secondary)",
              boxShadow: "0px 0px 1.5px 1.5px rgba(0,0,0,1)",
              height: "300px"

            }}
          >
            <s.TextMajor
              style={{
                textAlign: "center",
                color: "#000000",
                fontWeight: "bold"
              }}
            >
              Mint NFT
            </s.TextMajor>
            {Number(data.getMintedSupplyCounter) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "#000000" }}
                >
                  All Scenic Cups have been minted.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "#000000" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle style={{ textAlign: "center", color: "#000000" }}>
                {blockchain.account === "" || blockchain.smartContract === null ? (
                <>
                1 Scenic Cup costs ... {" "}{CONFIG.NETWORK.SYMBOL}
                </>
                ):(
                <>
                1 Scenic Cup costs {mintdisplay}{" "}{CONFIG.NETWORK.SYMBOL}
                </>)
                }
                </s.TextTitle>
                <s.SpacerLarge />
                <s.SpacerLarge />
                <s.SpacerLarge />
                <s.SpacerLarge />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <StyledButton
                      style={{
                        textAlign: "center",
                        color: "#000000",
                        fontSize: "22px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "#000000",
                      }}
                    >
                      Connect to {CONFIG.NETWORK.NAME}
                    </s.TextDescription>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "#000000",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    </s.Container>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        style={{
                          textAlign: "center",
                          color: "#000000",
                          fontSize: "22px",
                          }}
                        onClick={(e) => {
                          e.preventDefault();
                          mintNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "MINT"}
                      </StyledButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "#000000",
                      }}
                    >
                      {mintFeedback}
                    </s.TextDescription>
                  </>
                )}
              </>
            )}
          </s.Container>
          <s.SpacerLarge /> 
          <s.Container flex={1} jc={"center"} ai={"center"} // Rotating Image Container
          >
            <StyledImg // Rotating Image
              alt={"ScenicCupsGIF"}
              src={"/config/images/rotating.gif"}
            />
          </s.Container>
          <s.SpacerLarge />  
          <s.Container // Burn Container
            flex={2}
            jc={"flex-start"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(256,256,256,0.85)", // background of container
              padding: 8, // space around container content
              borderRadius: 0, // radius of container border
              border: "0px dashed var(--secondary)",
              boxShadow: "0px 0px 1.5px 1.5px rgba(0,0,0,1)",
              height: "300px"
            }}
          >
            <s.TextMajor
              style={{
                textAlign: "center",
                color: "#000000",
                fontWeight: "bold"
              }}
            >
              Burn NFT
            </s.TextMajor>
                <s.TextTitle
                  style=
                  {{
                    textAlign: "center",
                    color: "#000000",
                  }}
                    >
                      Enter your Token ID:
                </s.TextTitle>
                <s.SpacerMedium />
                <StyledForm>
                  <StyledInput
                      style={{
                      textAlign: "center",
                      color: "#000000",
                      fontSize: "24px",
                      }}
                      type="number"
                      id="burnInput"
                      class="burnInput"
                      placeholder="ID"
                    >
                  </StyledInput>
                </StyledForm>
                <s.SpacerMedium />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <StyledButton
                      style={{
                      textAlign: "center",
                      color: "#000000",
                      fontSize: "22px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "#000000",
                      }}
                    >
                      Connect to {CONFIG.NETWORK.NAME}
                    </s.TextDescription>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "#000000",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={burningNft ? 1 : 0}
                        style={{
                          textAlign: "center",
                          color: "#000000",
                          fontSize: "22px",
                          }}
                        onClick={(e) => {
                          e.preventDefault();
                          burnNFTs();
                          getData();
                        }}
                      >
                        {burningNft ? "BUSY" : "BURN"}
                      </StyledButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "#000000",
                      }}
                    >
                      {burnFeedback}
                    </s.TextDescription>
                  </>)
            }
          </s.Container>
        </ResponsiveWrapper>
        <ResponsiveWrapper flex={1} style={{ paddingLeft: 64, paddingRight: 64, paddingTop: 16, paddingBottom: 16 }}> 
          <s.Container flex={1} jc={"flex-start"} ai={"left"} style={{ // Minting Process Container
              backgroundColor: "rgba(256,256,256,0.85)", // background of container
              paddingLeft: 32,
              paddingTop: 8,
              borderRadius: 0, // radius of container border
              border: "1px solid #000000",
              boxShadow: "0px 0px 1.5px 1.5px rgba(0,0,0,1)",
              width: "100%",
              height: "300px"}}>
            <s.TextMajor
              style={{
                textAlign: "left",
                color: "#000000",
                fontWeight: "bold"
              }}
            >
              Minting Process
            </s.TextMajor>
            <s.TextDescription
            style={{
              textAlign: "left",
              color: "var(--primary-text)",
            }}
            >
            Step 1: Connect MetaMask wallet by pressing the "CONNECT" button.<br/>
            Step 2: Press the "MINT" button to initiate the transaction.<br/>
            Step 3: Confirm the transaction in MetaMask.<br/>
            Step 4: Wait for the transaction to complete.<br/>
            Step 5: The Scenic Cup NFT is now yours, thank you!
          </s.TextDescription>
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"flex-start"} ai={"left"} style={{ // Redemption Process Container
              backgroundColor: "rgba(256,256,256,0.85)", // background of container
              paddingLeft: 32,
              paddingTop: 8,
              borderRadius: 0, // radius of container border
              border: "1px solid #000000",
              boxShadow: "0px 0px 1.5px 1.5px rgba(0,0,0,1)",
              width: "100%",
              height: "300px",}}>
            <s.TextMajor
              style={{
                textAlign: "left",
                color: "#000000",
                fontWeight: "bold"
              }}
            >
              Redemption Process
            </s.TextMajor>
            <s.TextDescription
            style={{
              textAlign: "left",
              color: "var(--primary-text)",
            }}
            >
            Step 1: Connect MetaMask wallet by pressing the "CONNECT" button.<br/>
            Step 2: Enter your Token ID (1-100).<br/>
            Step 3: Press the "BURN" button to initiate the transaction.<br/>
            Step 4: Confirm the transaction in MetaMask.<br/>
            Step 5: Wait for the transaction to complete.<br/>
            Step 6: Submit the <a style={{color: "#000000", textDecoration: "underline"}} target="_blank" href="https://forms.gle/fFWfvVuKesd4WDAr7">redemption form</a> to receive the physical Scenic Cup.
          </s.TextDescription>
          </s.Container>
        </ResponsiveWrapper>
      </s.Container>
      <s.Container // Container for Bottom Content
        flex={1}
        ai={"left"}
        style={{ padding: 48, backgroundColor: "var(--primary)" }}
        image={"/config/images/bg_bottom.png"}
      >
      <ResponsiveWrapper style={{ paddingLeft: 64, paddingRight: 0, paddingTop: 16, paddingBottom: 16 }}>
        <s.Container 
        flex={1} 
        jc={"center"} 
        ai={"left"} 
        style={{
          backgroundColor: "rgba(256,256,256,0.75)", // background of container
          paddingLeft: 32,
          paddingTop: 8, // space around container content
          paddingBottom: 24,
          borderRadius: 0, // radius of container border
          border: "0px solid #000000",
          boxShadow: "0px 0px 1.5px 1.5px rgba(108,127,72,0.5)",
          width: "100%",}}
        >
          <s.TextMajor
            style={{
             textAlign: "left",
             color: "#000000",}}
          >
           Information
          </s.TextMajor>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
             textAlign: "left",
             color: "var(--primary-text)",}}
          >
            <em>Please make sure you are connected to {CONFIG.NETWORK.NAME} and the correct address. <br/>
            Please note:  Once you make the purchase, you cannot undo this action.</em>
         </s.TextDescription>
         <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "left",
             color: "var(--primary-text)",}}
          >
           We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract interaction to
           be successful.<br/>
           We recommend that you don't lower the gas limit.
         </s.TextDescription>
         <s.SpacerSmall/>
          <SmartContractButton
            style={{
              textAlign: "center",
              color: "#000000",
              fontSize: "12px",
              padding: "4px",}}
           onClick={(e) => {
              e.preventDefault();
              window.open(CONFIG.SCAN_LINK)}}
          >
           VIEW SC CONTRACT
          </SmartContractButton>
          <s.SpacerSmall/>
          <SmartContractButton 
            style={{
              textAlign: "center",
              color: "#000000",
              fontSize: "12px",
              padding: "4px",}}
           onClick={(e) => {
             e.preventDefault();
             window.open(CONFIG.SCAN_LINK)}} // TODO insert correct link (to rSC)
          >
           VIEW rSC CONTRACT
         </SmartContractButton> 
       </s.Container>
       <s.Container flex={1} jc={"center"} ai={"center"} style={{
          backgroundColor: "rgba(256,256,256,0)", // background of container
          padding: 12, // space around container content
          borderRadius: 0, // radius of container border
          border: "0px solid #000000",
          boxShadow: "0px 0px 0px 0px rgba(235,201,8,1)",
          width: "50%",}}>
       </s.Container>
      </ResponsiveWrapper>
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
    </s.Container>
    <s.Container // Container for Footer Content
        flex={1}
        ai={"left"}
        style={{ padding: 0, backgroundColor: "#000000" }}
      >
        <ResponsiveWrapper style={{ padding: 0 }}>
          <s.Container flex={1} jc={"center"} ai={"center"} style={{
              backgroundColor: "#000000", // background of container
              padding: 12, // space around container content
              borderRadius: 0, // radius of container border
              border: "0px var(--secondary)",
              boxShadow: "0px 0px 0px 0px rgba(0,0,0,1)",
              width: "100%",}}>
            <s.TextDescription
            style={{
              textAlign: "center",
              color: "#ffffff",
              fontSize: 16,
            }}
            >
            Project by <a style={{color: "#6c7f48", textDecoration: "none"}} href="https://www.ritziporzellan.ch" target="_blank">ritziporzellan.ch</a> | Smart Contracts and Interface by <a style={{color: "#6c7f48", textDecoration: "none"}} href="https://twitter.com/decentralix" target="_blank">@decentralix</a> | <a href="/license.txt" target="_blank" style={{color: "#6c7f48", textDecoration: "none"}}>License / Copyright</a>
            </s.TextDescription>
          </s.Container>
        </ResponsiveWrapper>
    </s.Container>
  </s.Screen>
  );
}
export default App;
